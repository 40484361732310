
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import title from './assets/title.png';
import happiness from './assets/happiness.png';
import end from './assets/end.png';
import output from './assets/output.png';
import marketing from './assets/marketing.jpg';
import docs from './assets/docs.jpg';
import blog from './assets/blog.jpg';
import summit from './assets/summit.jpg';
import things from './assets/things.gif';
import graphiql from './assets/graphiql.gif';
import wordpress from './assets/wordpress.gif';
import pizza from './assets/pizza.gif';
import dx from './assets/dx.jpg';
import gta from './assets/gta.png';
import {
  Split,
  SplitRight,
  Invert,
  Image,
  themes
} from 'mdx-deck';
import {
  Counter,
  CounterEdit,
  CounterHot,
  CounterProvider,
  CounterSamples,
  FooterContent
} from './components';
import {Grid} from 'theme-ui';
export const theme = { ...themes.prism,
  googleFont: 'https://fonts.googleapis.com/css2?family=Fira+Code',
  fonts: {
    heading: 'Boogaloo, sans-serif',
    body: 'sans-serif',
    monospace: "'Fira Code', monospace"
  },
  colors: {
    background: '#eecb00',
    text: '#7b0340',
    primary: '#a41075'
  },
  text: {
    heading: {
      textTransform: 'uppercase'
    }
  },
  styles: {
    img: {
      display: 'block',
      width: 'auto',
      height: 'auto'
    },
    li: {
      margin: '0.5em 0',
      textAlign: 'left'
    },
    code: {
      fontSize: '0.75em',
      margin: '0.5em !important'
    },
    inlineCode: {
      padding: '0.1em 0.2em',
      color: 'primary',
      backgroundColor: 'white'
    }
  },
  components: { ...themes.prism.components,
    code: props => {
      return themes.prism.components.code({ ...props,
        // to get around a bug with mdx-deck and certain lowercase words
        // https://github.com/jxnblk/mdx-deck/issues/650
        children: props.children.replace(/(gr)[àáâäãåā](phql)/g, '$1a$2')
      });
    }
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};
const Head = makeShortcode("Head");
const Footer = makeShortcode("Footer");
const Horizontal = makeShortcode("Horizontal");
const Steps = makeShortcode("Steps");
const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Head mdxType="Head">
  <link rel="icon" href="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/twitter/248/peace-symbol_262e.png" />
  <title>The DX Experience</title>
    </Head>
    <Footer mdxType="Footer">
  <FooterContent mdxType="FooterContent" />
    </Footer>

    <img src={title} style={{
      width: 765,
      margin: '80px 0'
    }} />
    <h2>{`by Trevor Blades`}</h2>
    <hr></hr>
    <Split mdxType="Split">
      <img src={dx} />
      <h1>{`Team DX @ Apollo`}</h1>
      <p>{`We produce educational content, organize events, and `}<strong parentName="p">{`build websites with Gatsby`}</strong>{`.`}</p>
    </Split>
    <hr></hr>
    <Horizontal mdxType="Horizontal">
      <img src={marketing} />
      <img src={docs} />
    </Horizontal>
    <Steps mdxType="Steps">
      <Horizontal mdxType="Horizontal">
        <img src={blog} />
        <img src={summit} />
      </Horizontal>
    </Steps>
    <hr></hr>
    <h1>{`Why we build with Gatsby`}</h1>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Blazing fast, static builds`}</li>
        <li parentName="ul">{`Utilizes tools we're familiar with`}</li>
        <li parentName="ul">{`Amazing `}<strong parentName="li">{`local development`}</strong>{` experience`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`Local development is groovy ☮️`}</h1>
    <Split style={{
      height: 'auto'
    }} mdxType="Split">
      <Steps mdxType="Steps">
        <p>{`{/`}<em parentName="p">{` - Incremental adoption `}</em>{`/}`}</p>
        <ul>
          <li parentName="ul">{`Hot module reloading 🔥`}</li>
          <li parentName="ul">{`GraphQL as a data layer`}</li>
          <li parentName="ul">{`Plugins for local development`}</li>
        </ul>
      </Steps>
      <img src={things} />
    </Split>
    <hr></hr>
    <Invert mdxType="Invert">
      <img src={happiness} style={{
        width: 689
      }} />
    </Invert>
    <hr></hr>
    {
      /* # Incremental adoption
      Gradually increase scope and complexity as necessary
      - Get started with minimal knowledge
      - Easy to add advanced features
      - Great starting place for _any_ React project
      ---
      # Minimal knowledge
      ```bash
      mkdir -p my-project/src/pages
      cd my-project
      npm init -y
      npm install gatsby react react-dom
      vi src/pages/index.js
      ```
      Just write a React component:
      ```jsx
      import React from 'react';
      export default () => <div>Hello world</div>;
      ```
      ```bash
      npx gatsby develop
      ```
      ---
      <Split>
      ```text
      my-project
      ├── public
      │   ├── favicon.ico
      │   ├── index.html
      │   ├── logo192.png
      │   ├── logo512.png
      │   ├── manifest.json
      │   └── robots.txt
      ├── src
      │   ├── App.css
      │   ├── App.js
      │   ├── App.test.js
      │   ├── index.css
      │   ├── index.js
      │   ├── logo.svg
      │   ├── serviceWorker.js
      │   └── setupTests.js
      ├── .gitignore
      ├── package.json
      ├── README.md
      └── yarn.lock
      ```
      My project directory after running `npx create-react-app my-project`
      <img src={gta} />
      </Split>
      ---
      # Add complexity as you need it
      <Split style={{height: 'auto'}}>
      ```text
      my-project
      ├── src/pages
      │   └── index.js
      ├── .gitignore
      ├── package-lock.json
      └── package.json
      ```
      <div
       style={{
         width: 500
       }}
      >
      ```diff
       my-project
       ├── src
      + │   ├── gatsby-theme-apollo
      + │   │   └── client.js
       │   └── pages
       │       └── index.js
       ├── .gitignore
      + ├── gatsby-config.js
       ├── package-lock.json
       └── package.json
      ```
      </div>
      </Split>
      --- */
    }
    <h1>{`Hot module reloading 🔥`}</h1>
    <p>{`Update the thing you're focused on without refreshing the entire page`}</p>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`App state persists between changes`}</li>
        <li parentName="ul">{`Maintain scroll position`}</li>
        <li parentName="ul">{`Increases speed of iteration`}</li>
      </ul>
    </Steps>
    <hr></hr>
    <CounterProvider mdxType="CounterProvider">
      <Grid columns={3} gap="1em" mdxType="Grid">
        <Counter mdxType="Counter" />
        <CounterEdit mdxType="CounterEdit" />
        <CounterHot mdxType="CounterHot" />
      </Grid>
      <CounterSamples mdxType="CounterSamples">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import React, {useState} from 'react';

export default function Counter() {
  const [count, setCount] = useState(0);

  function increment() {
    setCount(prevCount => prevCount + 1);
  }

  return (
    <button onClick={increment}>
      Clicked {count} times {count > 5 && '🐶'}
    </button>
  );
}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import React, {useState} from 'react';

export default function Counter() {
  const [count, setCount] = useState(0);

  function increment() {
    setCount(prevCount => prevCount + 1);
  }

  return (
    <button onClick={increment}>
      Clicked {count} times {count > 5 && '🦎'}
    </button>
  );
}
`}</code></pre>
      </CounterSamples>
    </CounterProvider>
    <hr></hr>
    <Split mdxType="Split">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`import {gräphql, useStaticQuery} from 'gatsby';

function HomePage() {
  const data = useStaticQuery(
    gräphql\`
      query ListPosts {
        allWpPost {
          nodes {
            id
            date
            title
            excerpt
          }
        }
      }
    \`
  );

  // render wordpress posts
}
`}</code></pre>
      <h1>{`GraphQL`}</h1>
      <p>{`Clearly express components' static data requirements`}</p>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">{`All static data organized in one place`}</li>
          <li parentName="ul">{`GraphQL queries benefit from HMR`}</li>
          <li parentName="ul">{`Great tools to `}<strong parentName="li">{`browse your graph`}</strong>{` and compose queries`}</li>
        </ul>
      </Steps>
    </Split>
    <hr></hr>
    <h1>{`Browse your graph`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`You can now view my-project in the browser.
⠀
  http://localhost:8000/
⠀
View GraphiQL, an in-browser IDE, to explore your site's data and schema
⠀
  http://localhost:8000/___gräphql
⠀
Note that the development build is not optimized.
To create a production build, use gatsby build
`}</code></pre>
    <hr></hr>
    <img src={pizza} style={{
      height: 700
    }} />
    <hr></hr>
    <h1>{`GraphiQL`}</h1>
    <img src={graphiql} />
    <p>{`Build queries like you build a pizza 🍕`}</p>
    <hr></hr>
    <h1>{`Plugins for local development`}</h1>
    <p>{`Add value to your development environment`}</p>
    <Steps mdxType="Steps">
      <ul>
        <li parentName="ul">{`Publicly accessible local dev URL: `}<inlineCode parentName="li">{`gatsby-plugin-ngrok-tunneling`}</inlineCode></li>
        <li parentName="ul">{`Check for broken links in Markdown: `}<inlineCode parentName="li">{`gatsby-remark-check-links`}</inlineCode></li>
        <li parentName="ul">{`Hot reloading content from Wordpress: `}<inlineCode parentName="li">{`gatsby-source-wordpress-experimental`}</inlineCode></li>
      </ul>
    </Steps>
    <hr></hr>
    <h1>{`Check for broken links in Markdown`}</h1>
    <p><strong parentName="p">{`Statically`}</strong>{` analyze Markdown links during the build phase`}</p>
    <img src={output} />
    <p><inlineCode parentName="p">{`gatsby-remark-check-links`}</inlineCode></p>
    <hr></hr>
    <h1>{`Hot reloading content from Wordpress`}</h1>
    <img src={wordpress} />
    <p><inlineCode parentName="p">{`gatsby-source-wordpress-experimental`}</inlineCode></p>
    <hr></hr>
    <h1>{`...and many more?`}</h1>
    <p>{`Let me know! 🗣`}</p>
    <hr></hr>
    <Invert mdxType="Invert">
      <img src={happiness} style={{
        width: 689
      }} />
    </Invert>
    <hr></hr>
    <h1>{`Blazing fast local development`}</h1>
    {
      /* - Incremental adoption */
    }
    <ul>
      <li parentName="ul">{`Hot module reloading 🔥`}</li>
      <li parentName="ul">{`GraphQL as a data layer`}</li>
      <li parentName="ul">{`Plugins for local development`}</li>
    </ul>
    <hr></hr>
    <img src={end} style={{
      marginBottom: 40
    }} />
    <h2>{`experience.trevorblades.com`}</h2>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;